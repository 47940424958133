<template>
    <div class="my-6 px-4 flex flex-wrap gap-1">
        <UIButton
            v-for="(button, index) in buttons"
            @click="handleButton(button)"
            :key="`button-${index}`"
            :type="button.type"
            :class="{ 'flex-1': button.flex }"
            :loading="submitting"
            :icon="button.icon"
        >
            {{ button.title }}
        </UIButton>
    </div>
</template>

<script setup>
    const moduleHelpers = useModuleHelpers()
    const { snackbar } = useSnackbars()

    const props = defineProps({
        config: Object,
    })

    const submitting = ref(false)
    const buttons = ref(props.config.data.buttons)

    const handleButton = (button) => {
        submitting.value = true

        return moduleHelpers.handleClick(button, null, false)
            .finally(() => {
                submitting.value = false
            })
    }

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })
</script>
