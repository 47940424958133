<template>
    <div>
        <FunnelFormPrefill
            v-if="config.data.students && config.data.students.length > 0 && !submitted"
            :students="config.data.students"
            :entry-generation-url="config.data.entry_generation_url"
            :student-deletion-url="config.data.student_deletion_url"
            @entry="updateEntry"
        />

        <div v-if="loadingScript" class="flex justify-center py-12">
            <UILoading class="w-12 h-12" />
        </div>
        <div ref="formContainer" class="-my-4" />
    </div>
</template>

<script setup>
    import api from '@/services/api'
    import FunnelFormPrefill from '@/components/ui/FunnelForm/FunnelFormPrefill.vue'

    const moduleHelpers = useModuleHelpers()

    const props = defineProps({
        config: Object,
    })

    const entry = ref(null)
    const loadingScript = ref(true)
    const formContainer = ref(null)
    const formController = ref(null)
    const submitted = ref(false)

    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            let shouldAppend = false
            let el = document.querySelector(`script[src="${src}"]`)

            if (!el) {
                el = document.createElement('script')
                el.type = 'text/javascript'
                el.async = true
                el.src = src
                shouldAppend = true
            } else if (el.hasAttribute('data-loaded')) {
                resolve(el)
                return
            }

            el.addEventListener('error', reject)
            el.addEventListener('abort', reject)
            el.addEventListener('load', () => {
                el.setAttribute('data-loaded', true)
                resolve(el)
            })

            if (shouldAppend) {
                document.head.appendChild(el)
            }
        })
    }

    const addForm = () => {
        if (!formContainer.value || !window.digistorm?.forms?.create) {
            throw new Error('Unable to initialise form')
        }

        if (!props.config?.data?.form_uuid) {
            throw new Error('Unable to initialise form, missing form id')
        }
        if (!props.config?.data?.school_code) {
            throw new Error('Unable to initialise form, missing school code')
        }

        const funnelUrl = trimEnd(import.meta.env.VITE_APP_FUNNEL_URL ?? 'https://app.digistorm.com', '/')
        const apiUrl = funnelUrl.replace('//', `//${props.config.data.school_code}.`)

        formController.value = window.digistorm.forms.create({
            target: formContainer.value,
            formId: props.config.data.form_uuid,
            apiUrl: `${ apiUrl }/api/v1/forms/form`,
            legacy: false,
            hideWrapper: true,
            entry: toRaw(entry.value) ?? {},
            onFormSubmit: (entryUuid) => {
                if (submitted.value) {
                    return
                }

                submitted.value = true

                // Send the entry uuid to the server
                if (props.config?.data?.entry_submitted_url) {
                    api.post(props.config.data.entry_submitted_url, { entry_uuid: entryUuid })
                        .then(({ data }) => moduleHelpers.handleActions(data.actions))
                        .catch((e) => {
                            if (moduleHelpers.isActionException(e)) {
                                moduleHelpers.handleActionException(
                                    e.response.data,
                                )
                            }
                        })
                }
            },
        })
    }

    const updateEntry = (value) => {
        entry.value = value

        if (loadingScript.value) {
            return
        }

        formController.value?.$destroy()
        addForm()
    }

    // Watch for changes to the config which will happen if the module is reloaded after clearing
    // saved pre-fill data for example
    watch(() => props.config, (value) => {
        updateEntry(props.config.data.entry)
    })

    onMounted(() => {
        entry.value = props.config.data.entry

        loadScript('https://cdn.digistorm.com.au/forms/latest.js')
            .then(() => {
                loadingScript.value = false
                addForm()
            })
    })

    onUnmounted(() => {
        formController.value?.$destroy()
    })
</script>
