<template>
    <UIDetailsCard
        :details="config.data.details"
        :title="config.data.title"
        :header="config.data.header"
        :loading="loading"
        @click="onClick"
    />
</template>

<script setup>
    const props = defineProps({
        config: Object,
    })

    const moduleStore = useModuleStore()

    const moduleHelpers = useModuleHelpers()

    const loading = ref(false)
    const clicking = ref(false)
    let loadingDelay = null

    const onClick = (item) => {
        if (!item || clicking.value) {
            return
        }

        clicking.value = true
        loadingDelay = delay(() => { loading.value = true }, 1000)
        moduleHelpers.handleClick(item, null, false)
            .finally(() => {
                clearTimeout(loadingDelay)
                loading.value = false
                clicking.value = false
            })
    }
</script>
