<template>
    <div class="pb-6">
        <div v-if="hasHeaderContent && !headerImage" class="px-4 pt-3 mb-6">
            <div v-if="badges.length" class="flex flex-wrap items-center">
                <DBadge
                    class="mb-2 mr-1"
                    v-for="(badge, index) in badges"
                    :key="`badge-${index}`"
                    :color="badge.color"
                    :icon="badge.icon"
                    rounded
                >
                    {{badge.text}}
                </DBadge>
            </div>
            <img
                v-if="content.logo"
                :src="content.logo.url"
                :alt="content.logo.alt"
                class="max-h-[80px] mb-4"
            />
            <UIHtml :html="content.title" class="prose text-lg font-medium mb-1 text-gray-900" />
            <UIHtml v-if="content.subtitle" :html="content.subtitle" class="prose prose-p:my-2 first:prose-p:mt-0 text-sm text-gray-600" />
            <div v-if="hasAvatar" class="flex items-center text-sm mb-6 mt-4">
                <UIAvatar
                    v-for="(avatar, index) in avatars"
                    :key="index"
                    :avatar="avatar"
                    class="border-2 border-white box-content"
                    :class="{
                        '-ml-2': index,
                    }"
                />
                <div v-if="avatars.length === 1 && avatars[0].description" class="text-gray-700 ml-2 text-[13px]">
                    {{ avatars[0].description }}
                </div>
            </div>
        </div>

        <div v-else-if="headerImage" class="relative h-[300px] md:h-[540px] bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: `url(${headerImage})` }">
            <div class="fixed top-0 left-0 right-0 h-[56px] bg-gradient-to-b from-black/80 to-black to-transparent" />
        </div>

        <div :class="{ 'flex flex-col items-center bg-white pt-10 rounded-2xl -mt-4 relative': headerImage }">
            <div v-if="headerImage && content.title" class="w-full px-4 -mt-4">
                <div v-if="avatar" class="flex items-center space-x-2 text-sm mb-6">
                    <UIAvatar v-if="avatar" :avatar="avatar" />
                    <div v-if="avatar.description">{{ avatar.description }}</div>
                </div>
                <div v-if="badges.length" class="flex flex-wrap items-center">
                    <DBadge
                        class="mb-2 mr-1"
                        v-for="(badge, index) in badges"
                        :key="`badge-${index}`"
                        :color="badge.color"
                        :icon="badge.icon"
                        rounded
                    >
                        {{badge.text}}
                    </DBadge>
                </div>
                <UIHtml :html="content.title" class="prose text-lg font-medium mb-1 text-gray-900" />
                <UIHtml v-if="content.subtitle" :html="content.subtitle" class="prose text-sm text-gray-600" />
            </div>
            <div class="pb-[var(--beyond-safe-area-inset-bottom)] w-full">
                <ModuleComponents :modules="props.config.items">
                    <template v-slot:empty>
                        <Empty v-if="displayEmptyDetailsNotice" />
                    </template>
                </ModuleComponents>
            </div>
        </div>

        <Teleport :to="headerMenuElement">
            <div v-if="hasMenuOptions" :id="modalUuid" class="w-[38px] h-[38px] flex items-center justify-center rounded-full" :class="{ 'bg-white': headerImage }">
                <PhIcon icon="dots-three-vertical" weight="bold" :size="24" />
            </div>
        </Teleport>

        <IonModal ref="modal" :trigger="modalUuid" :initial-breakpoint="1" :breakpoints="[0, 1]">
            <div class="py-6">
                <div>
                    <UISimple
                        v-if="calendarData"
                        title="Add to calendar"
                        :icon="{ icon: 'calendar-plus', icon_url: null, color: null }"
                        :right-icon="{ icon: 'caret-right', icon_url: null, color: null }"
                        :border="false"
                        @click="addToCalendar"
                    />
                    <UISimple
                        v-if="sharingData"
                        title="Share"
                        :icon="{ icon: 'share', icon_url: null, color: null }"
                        :right-icon="{ icon: 'caret-right', icon_url: null, color: null }"
                        :border="false"
                        @click="share"
                    />
                    <UISimple
                        v-for="(item, index) in menuItems"
                        :key="`menu-item-${index}`"
                        :title="item.title"
                        :icon="{ icon: item.icon, icon_url: null, color: null }"
                        @click="openMenuItem(item)"
                    />
                </div>
            </div>
        </IonModal>
    </div>
</template>

<script setup>
    import { DBadge } from '@digistorm/spark'
    import { onIonViewWillEnter, onIonViewWillLeave } from '@ionic/vue'
    import { v4 as uuidv4 } from 'uuid'
    import { Share } from '@capacitor/share'
    import { Calendar } from '@awesome-cordova-plugins/calendar'
    import UIHtml from '@/components/ui/UIHtml.vue'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'
    import PhIcon from '@/components/app/PhIcon.vue'
    import UIAvatar from '@/components/ui/UIAvatar.vue'
    import { statusBar } from '@/services'
    import ModuleComponents from '@/components/ModuleComponents.vue'

    const props = defineProps({
        config: Object,
        headerMenu: HTMLDivElement,
    })

    const deviceStore = useDeviceStore()
    const { snackbar } = useSnackbars()
    const moduleHelpers = useModuleHelpers()

    const { isIos } = storeToRefs(deviceStore)
    const modal = ref(null)
    const modalUuid = ref(uuidv4())

    const content = computed(() => (props.config ? props.config.data : null))
    const items = computed(() => (props.config ? props.config.items : null))

    const hasHeaderContent = computed(() => {
        return get(content.value, 'title') || get(content.value, 'subtitle') || get(content.value, 'logo')
    })

    const avatar = computed(() => {
        return get(content.value, 'avatar')
    })

    const avatars = computed(() => {
        return get(content.value, 'avatars')
    })

    const badges = computed(() => {
        return get(content.value, 'badges', [])
    })

    const sharingData = computed(() => {
        return get(props.config, 'meta.sharing_data')
    })

    const calendarData = computed(() => {
        return get(props.config, 'meta.calendar_data')
    })

    const menuItems = computed(() => {
        return get(props.config, 'meta.menu_items', [])
    })

    const hasMenuOptions = computed(() => {
        return !isNil(sharingData.value) || !isNil(calendarData.value) || menuItems.value.length
    })

    const headerImage = computed(() => {
        return get(props.config, 'meta.header_image')
    })

    const hasAvatar = computed(() => {
        return avatars.value && avatars.value.length
    })

    const displayEmptyDetailsNotice = computed(() => {
        return !hasHeaderContent.value && !hasAvatar.value
    })

    const headerMenuElement = computed(() => {
        return props.headerMenu?.querySelector('#header-menu')
    })

    const createEvent = (options = {}) => {
        Calendar.createEventWithOptions(
            calendarData.value.title,
            calendarData.value.location ?? '',
            calendarData.value.body,
            new Date(calendarData.value.start * 1000),
            new Date(calendarData.value.end * 1000),
            options,
        )
            .then((event) => {
                modalController.dismiss()

                snackbar({
                    message: 'Event added to calendar',
                    type: 'success',
                })
            })
            .catch((e) => {
                console.error('error', e)
                snackbar({
                    message: 'Error adding event to calendar',
                    type: 'error',
                })
            })
    }

    const createEventWithPrimaryCalendar = (event) => {
        const options = {}
        if (isIos.value) {
            createEvent()
            return
        }
        Calendar.listCalendars().then((calendars) => {
            const primaryCalendar = calendars.find((calendar) => calendar.isPrimary)
            if (primaryCalendar) {
                options.calendarId = primaryCalendar.id
            }
            createEvent(options)
        })
    }

    const addToCalendar = () => {
        const event = {
            title: calendarData.value.title,
            notes: calendarData.value.body,
            location: calendarData.value.location ?? '',
            startDate: new Date(calendarData.value.start * 1000),
            endDate: new Date(calendarData.value.end * 1000),
        }

        Calendar.hasWritePermission()
            .then((perm) => {
                if (!perm) {
                    Calendar.requestWritePermission()
                        .then((event) => {
                            createEventWithPrimaryCalendar(event)
                        }).catch(() => {
                            snackbar({
                                title: 'No access to calendar.',
                                message: "You can enable access in your device's privacy settings.",
                                type: 'error',
                            })
                        })

                    return
                }
                createEventWithPrimaryCalendar(event)
            })
            .catch(() => {
                snackbar({
                    message: 'Error adding event to calendar',
                    type: 'error',
                })
            })
    }

    const share = () => {
        return Share.share({
            title: sharingData.value.title,
            text: sharingData.value.body,
            url: null,
            dialogTitle: 'Pick an app',
        })
            .catch((e) => {
                if (e === 'Share canceled') {
                    return
                }
                throw e
            })
    }

    const openMenuItem = (item) => {
        moduleHelpers.handleClick(item)
            .finally(() => {
                modal.value.$el?.dismiss(null, 'cancel')
            })
    }

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
        if (headerImage.value) {
            statusBar.setTheme('#000000')
        }
    })

    onIonViewWillEnter(() => {
        if (headerImage.value) {
            statusBar.setTheme('#000000')
        }
    })

    onIonViewWillLeave(() => {
        if (headerImage.value) {
            statusBar.setTheme()
        }
    })
</script>
