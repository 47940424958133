<template>
    <div class="container mx-auto max-w-xl flex-1 px-4 md:px-0 mb-8">
        <template v-if="!onlyOneStudent">
            <DField label="Students" required required-color="text-red-600" />
            <div
                v-for="student in students"
                :key="student.external_id"
                class="w-full flex items-center p-4 border mb-3 rounded-lg transition-all"
                :class="selectedStudents[student.external_id] ? 'border-primary-600' : 'border-gray-300'"
                @click="toggleStudent(student)"
            >
                <UIAvatar :avatar="{ text: avatarText(student), image_url: null }" />
                <div class="mx-3">
                    <div class="text-sm text-gray-900">
                        {{ student.name }}
                    </div>
                </div>
                <DCheckbox
                    v-model="selectedStudents[student.external_id]"
                    class="ml-auto pointer-events-none"
                />
                <DButton v-if="studentDeletionUrl" theme="transparent" class="ml-2 text-action-destructive" size="sm" @click="deleteStudent(student)">
                    <DIcon icon="x-circle" icon-set="heroicons" size="w-6 h-6" class="!flex" type="small" />
                </DButton>
            </div>
        </template>
        <template v-else>
            <div class="w-full flex items-center pt-4">
                <UIAvatar :avatar="{ text: avatarText(students[0]), image_url: null, size: 'small' }" />
                <div class="mx-3">
                    <div class="text-gray-900">
                        {{ students[0].name }}
                    </div>
                </div>
                <DButton v-if="studentDeletionUrl" theme="transparent" class="ml-auto text-action-destructive" size="sm" @click="deleteStudent(students[0])">
                    <DIcon icon="x-circle" icon-set="heroicons" size="w-6 h-6" class="!flex" type="small" />
                </DButton>
            </div>
        </template>
    </div>
</template>

<script setup>
    import {
        DField, DCheckbox, DButton, DIcon,
    } from '@digistorm/spark'
    import UIAvatar from '@/components/ui/UIAvatar.vue'
    import api from '@/services/api'
    import { events } from '@/composables/events'

    const moduleHelpers = useModuleHelpers()

    const emit = defineEmits(['entry'])

    const props = defineProps({
        students: Array,
        entryGenerationUrl: String,
        studentDeletionUrl: String,
    })

    const abortController = ref(null)
    const selectedStudents = ref({})
    if (props.students.length && props.students[0]) {
        selectedStudents.value[props.students[0].external_id] = true
    }

    const onlyOneStudent = computed(() => {
        return props.students.length === 1
    })

    const studentsHaveUniqueFirstNameInitial = computed(() => {
        const uniqueInitials = uniqBy(props.students, (student) => String(student.name)[0])
        return uniqueInitials.length === props.students?.length
    })

    const selectedStudentIds = computed(() => {
        return transform(selectedStudents.value, (selected, studentIsSelected, studentId) => {
            if (studentIsSelected) {
                selected.push(studentId)
            }
        }, [])
    })

    const avatarText = (student) => {
        return String(student.name).substring(0, studentsHaveUniqueFirstNameInitial.value ? 1 : 2)
    }

    const toggleStudent = (student) => {
        selectedStudents.value[student.external_id] = !selectedStudents.value[student.external_id]
    }

    const deleteStudent = (student) => {
        events.emit('toggleLoadingOverlay', true)

        api.post(props.studentDeletionUrl, {
            student: student.external_id,
        })
            .then(({ data }) => {
                return moduleHelpers.handleActions(data.actions)
            })
            .finally(() => {
                events.emit('toggleLoadingOverlay', false)
            })
    }

    watch(selectedStudentIds, (value, old) => {
        abortController.value?.abort()
        abortController.value = new AbortController()

        if (isEmpty(value)) {
            emit('entry', {})
            return
        }

        api.get(props.entryGenerationUrl, {
            params: {
                students: selectedStudentIds.value,
            },
            signal: abortController.value.signal,
        })
            .then(({ data }) => {
                emit('entry', data?.items?.[0].data?.entry)
            })
    })
</script>
