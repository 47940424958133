import { defineStore, storeToRefs } from 'pinia'

import { api, mock } from '@/services'

export const useVersionStore = defineStore('version', {
    state: () => {
        return {
            loaded: false,
            version: {},
        }
    },
    actions: {
        // Initial loading of the dashboard
        async load() {
            const { appId } = useAppStore()

            // If mocking use the mock version
            if (mock.mocking()) {
                this.version = mock.version()
                this.loaded = true
                return
            }

            return api.get(`/${appId}/versions`)
                .then(({ data }) => {
                    this.version = data
                    this.loaded = true
                })
        },
    },
})
