<template>
    <div
        class="px-4 mb-8"
        :class="{
            'border border-gray-300 rounded-lg mx-4 my-4 p-4': border,
        }"
    >
        <div v-if="title" class="text-gray-600 text-[13px] uppercase tracking-wider mb-3">
            {{title}}
        </div>
        <StudentSelector v-if="students" :students="students" @change="onStudentsChange" />
        <SchemaForm
            v-model="entry"
            :schema="schema"
            :form-errors="errors"
        />
        <div v-if="buttons" class="mt-8 flex flex-wrap gap-1">
            <UIButton
                v-for="(button, index) in buttons"
                @click="handleButton(button, index)"
                :key="`button-${index}`"
                :type="button.type"
                :class="{ 'flex-1': button.flex }"
                :loading="submitting && submittingIndex === index"
                :disabled="submitting"
                :icon="button.icon"
                :left-icon="button.left_icon"
            >
                {{ button.title }}
            </UIButton>
        </div>
        <div v-else class="mt-6 flex flex-row-reverse">
            <UIButton @click="handleButton()" :loading="submitting">
                {{ config.data.button_text || 'Submit' }}
            </UIButton>
        </div>
    </div>
</template>

<script setup>
    import { SchemaForm } from '@digistorm/forms'
    import StudentSelector from '@/components/ui/Form/StudentSelector.vue'

    import linkType from '@/constants/linkType'

    const moduleHelpers = useModuleHelpers()
    const { snackbar } = useSnackbars()

    const props = defineProps({
        config: Object,
    })

    const schema = ref(props.config.data.schema)
    const buttons = ref(props.config.data.buttons)
    const students = ref(props.config.data.students)
    const title = ref(props.config.data.title)
    const border = ref(props.config.data.border)
    const selectedStudentIds = ref([])

    const entry = ref({})
    const errors = ref({})
    const submitting = ref(false)
    const submittingIndex = ref(null)

    provide('formErrors', errors)

    const onStudentsChange = (selectedStudents) => {
        selectedStudentIds.value = selectedStudents
    }

    const handleButton = (button = null, index = null) => {
        if (!button) {
            // Buttons weren't provided, use default url
            button = {
                link: {
                    type: linkType.apiAction,
                    url: props.config.data.url,
                },
            }
        }

        submitting.value = true
        submittingIndex.value = index

        // Set the payload on the link and include the selected students
        if (students.value) {
            button.link.payload = assign(entry.value, { students: selectedStudentIds.value })
        } else {
            button.link.payload = entry.value
        }

        return moduleHelpers.handleClick(button, null, false)
            .catch((error) => {
                if (error?.response?.status === 422) {
                    errors.value = error.response.data?.errors
                    return
                }

                console.error('error', error)
                snackbar({
                    message: 'Error submitting form',
                    type: 'error',
                })
                throw error
            })
            .finally(() => {
                submitting.value = false
                submittingIndex.value = null
            })
    }

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })
</script>
