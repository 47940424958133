<template>
    <div aria-hidden="true" class="inline-flex w-[80px] h-[80px] relative text-primary-700/30 dark:text-primary-300/30 fill-primary-700 dark:fill-primary-300">
        <div class="absolute w-[80px] h-[80px] overflow-hidden">
            <svg class="w-full h-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="80" height="80">
                <path fill-rule="evenodd" fill="currentColor" d="m40 79.2c-21.7 0-39.2-17.5-39.2-39.2 0-21.7 17.5-39.2 39.2-39.2 21.7 0 39.2 17.5 39.2 39.2 0 21.7-17.5 39.2-39.2 39.2zm27-39.2c0-14.9-12.1-27-27-27-14.9 0-27 12.1-27 27 0 14.9 12.1 27 27 27 14.9 0 27-12.1 27-27z" />
            </svg>
            <svg class="absolute top-0 left-0 w-full h-full animate-spin origin-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="80" height="80">
                <path fill="currentFill" d="m73.1 40c3.3 0 6.1-2.8 5.6-6.1-0.5-3-1.3-6-2.5-8.9-2-4.7-4.9-9.1-8.5-12.7-3.6-3.6-8-6.5-12.7-8.5-2.9-1.2-5.9-2-8.9-2.5-3.3-0.5-6.1 2.3-6.1 5.6 0 3.4 2.8 6.1 6.1 6.8q2.1 0.5 4.2 1.4c3.3 1.3 6.3 3.3 8.8 5.8 2.5 2.5 4.5 5.5 5.8 8.8q0.9 2.1 1.4 4.2c0.7 3.3 3.4 6.1 6.8 6.1z" />
            </svg>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>
