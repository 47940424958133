<template>
    <div class="pb-[var(--beyond-safe-area-inset-bottom)]">
        <div v-if="hasReadItems" class="flex items-center border-b border-gray-200 justify-between px-4 pb-4">
            <DTabs type="pill" @selected="onTabSelected">
                <DTab name="All" active />
                <DTab name="Unread" />
            </DTabs>

            <span
                v-if="readAllUrl"
                class="text-primary-700 text-sm font-medium border-b border-primary-700 mr-4"
                @click="readAll"
            >
                Mark all as read
            </span>
        </div>

        <div v-if="displaySearch" class="flex items-center justify-between pb-2 px-4" :class="{ 'pt-2': !hasReadItems }">
            <DField id="Search">
                <DInput
                    v-model="search"
                    left-icon="magnifying-glass"
                    placeholder="Search"
                    rounded
                />
            </DField>
        </div>

        <div v-if="searching" class="flex justify-center pt-[100px]">
            <UILoading class="w-12 h-12" />
        </div>

        <div v-if="!moduleStore.fetching && !searching" data-cy="list" ref="listContent" :class="{ 'h-full': useVirtualScroll }">
            <slot>
                <component
                    :is="useVirtualScroll ? VirtualModuleComponents : ModuleComponents"
                    :modules="flatListItems"
                    :filter="combinedFilter"
                >
                    <template v-slot:empty>
                        <template v-if="unread">
                            <Empty icon="empty-list">
                                <template v-slot:subtitle>
                                    You have no unread updates.
                                </template>
                            </Empty>
                        </template>
                        <template v-else-if="filteringEnabled || search">
                            <!-- No items after filtering -->
                            <Empty icon="empty-search">
                                <template v-slot:title>
                                    No result found.
                                </template>

                                <template v-slot:subtitle>
                                    Try changing the filters or search term.
                                </template>
                            </Empty>
                        </template>
                        <template v-else>
                            <!-- No items -->
                            <slot name="no-items">
                                <Empty />
                            </slot>
                        </template>
                    </template>
                </component>
            </slot>
        </div>

        <template v-if="headerMenuElement">
            <Teleport :to="headerMenuElement">
                <FiltersButton v-if="filterMenuItems.length > 1" :uuid="filterModalUuid" :count="selectedFilterMenuItems.length" />
            </Teleport>
            <FiltersModal :uuid="filterModalUuid" :items="filterMenuItems" @toggleAll="toggleFilterMenuAll" @change="filtersChanged" />

            <Teleport :to="headerMenuElement">
                <div v-if="moreMenuItems.length" :id="moreModalUuid" class="w-[38px] h-[38px] flex items-center justify-center rounded-full">
                    <PhIcon icon="dots-three-vertical" weight="bold" :size="24" />
                </div>
            </Teleport>

            <IonModal ref="modal" :trigger="moreModalUuid" :initial-breakpoint="1" :breakpoints="[0, 1]">
                <div class="py-6">
                    <div>
                        <UISimple
                            v-for="(item, index) in moreMenuItems"
                            :key="`menu-item-${index}`"
                            :title="item.title"
                            :icon="{ icon: item.icon, icon_url: null, color: null }"
                            @click="openMoreMenuItem(item)"
                        />
                    </div>
                </div>
            </IonModal>
        </template>
    </div>
</template>

<script setup>
    import {
        DTabs, DTab, DField, DInput,
    } from '@digistorm/spark'
    import { v4 as uuidv4 } from 'uuid'
    import { filter, debounce } from 'lodash'
    import PhIcon from '@/components/app/PhIcon.vue'
    import UISimple from '@/components/ui/ListItemLayouts/UISimple.vue'
    import ModuleComponents from '@/components/ModuleComponents.vue'
    import VirtualModuleComponents from '@/components/VirtualModuleComponents.vue'
    import Empty from '@/components/ui/Empty.vue'
    import FiltersModal from '@/components/app/filters/FiltersModal.vue'
    import FiltersButton from '@/components/app/filters/FiltersButton.vue'
    import { filterPreferences } from '@/services/filterPreferences'

    const props = defineProps({
        config: Object,
        headerMenu: HTMLDivElement,
    })

    const moduleHelpers = useModuleHelpers()
    const moduleStore = useModuleStore()

    const modal = ref(null)
    const searchFilter = ref(null)
    const readFilter = ref(null)

    const search = ref('')
    const searching = ref(false)
    const unread = ref(false)
    const listContent = ref(null)
    const filterModalUuid = ref(uuidv4())
    const moreModalUuid = ref(uuidv4())
    const filterMenuAll = ref(false)
    const selectedFilterMenuItems = ref([])

    const listConfig = ref(props.config)
    const filterMenuItems = ref(get(listConfig.value, 'meta.filters', []))

    const flatListItems = computed(() => {
        const items = get(listConfig.value, 'items', [])

        // flatten nested items
        return flatMap(items, (item) => [
            omit(item, 'items'),
            ...item.items ?? [],
        ])
    })

    const useVirtualScroll = computed(() => {
        return get(listConfig.value, 'meta.virtual_scroll')
    })

    const hasReadItems = computed(() => {
        return get(listConfig.value, 'meta.read_filter')
    })

    const readAllUrl = computed(() => {
        return get(listConfig.value, 'meta.read_multiple_url')
    })

    const displaySearch = computed(() => {
        return !!get(listConfig.value, 'meta.search')
    })

    const searchUrl = computed(() => {
        return get(listConfig.value, 'meta.search.url')
    })

    const searchProperties = computed(() => {
        return get(listConfig.value, 'meta.search.properties')
    })

    const filterType = computed(() => {
        return get(listConfig.value, 'meta.search.type')
    })

    const headerMenuElement = computed(() => {
        return props.headerMenu?.querySelector('#header-menu')
    })

    const moreMenuItems = computed(() => {
        return get(listConfig.value, 'meta.menu_items', [])
    })

    const filteringEnabled = computed(() => {
        return selectedFilterMenuItems.value.length && !filterMenuAll.value
    })

    const menuFilter = computed(() => {
        if (!filteringEnabled.value) {
            // Don't filter if there are no selected filter options or if all selected
            return null
        }
        // Filter by selected menu options
        return (item) => {
            return !chain(item)
                .get('meta.tags')
                .intersection(selectedFilterMenuItems.value)
                .isEmpty()
                .value()
        }
    })

    const combinedFilter = computed(() => {
        if (!menuFilter.value && !readFilter.value && !searchFilter.value) {
            return null
        }
        return (item) => {
            return (menuFilter.value?.(item) ?? true)
                && (readFilter.value?.(item) ?? true)
                && (searchFilter.value?.(item) ?? true)
        }
    })

    const canStoreFilterPreferences = computed(() => {
        return listConfig.value?.meta?.id && filterMenuItems.value.length
    })

    const toggleFilterMenuAll = (value) => {
        filterMenuAll.value = value
    }

    const filtersChanged = (value) => {
        if (canStoreFilterPreferences.value) {
            filterPreferences.setEnabledFilters(listConfig.value.meta.id, value)
        }
        selectedFilterMenuItems.value = value
    }

    const openMoreMenuItem = (item) => {
        moduleHelpers.handleClick(item)
            .finally(() => {
                modal.value.$el?.dismiss(null, 'cancel')
            })
    }

    const onTabSelected = (index) => {
        if (index === 0) {
            readFilter.value = null
            unread.value = false
        } else {
            unread.value = true
            readFilter.value = (item) => {
                return (item.data?.read ?? false) === false
            }
        }
    }

    const readAll = () => {
        const unreadItems = filter(listConfig.value.items, (item) => !item?.data?.read)
        each(unreadItems, (item) => {
            item.data.read = true
        })
        moduleStore.markMultipleAsRead(readAllUrl.value, map(unreadItems, 'meta.id'))
    }

    const loadStoredFilters = async () => {
        const filtersIds = await filterPreferences.getEnabledFilters(listConfig.value.meta.id)
        filtersIds.forEach((filterId) => {
            const filter = find(filterMenuItems.value, { value: filterId })
            if (filter) {
                filter.enabled = true
            }
        })
        selectedFilterMenuItems.value = filtersIds
    }

    const debounceSearch = debounce((searchVal) => {
        moduleStore.loadContent(searchUrl.value, { search: searchVal })
            .then(({ data }) => {
                listConfig.value = data
            })
            .finally(() => {
                searching.value = false
            })
    }, 600)

    watch(search, (searchVal) => {
        const isRemote = filterType.value === 'remote'
        if (!searchVal && !isRemote) {
            searchFilter.value = null
            return
        }

        if (!isRemote) {
            const query = toLower(searchVal)
            searchFilter.value = (item) => {
                return some(searchProperties.value, (property) => {
                    return toLower(get(item, property)).includes(query)
                })
            }
            return
        }

        if (searchUrl.value) {
            searching.value = true
            // Search using provided url
            debounceSearch(searchVal)
            return
        }

        // Search using base module url
        moduleStore.setQueryParams({
            search: searchVal,
        })
        moduleStore.loadModule()
    })

    watch(() => props.config, (newVal) => {
        listConfig.value = newVal
    })

    onMounted(() => {
        if (canStoreFilterPreferences.value) {
            loadStoredFilters()
        }
        moduleHelpers.setAnalyticsScreenFromConfig(listConfig.value)
        search.value = new URLSearchParams(moduleStore.url).get('search') || ''
    })
</script>
