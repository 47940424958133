<template>
    <DynamicScroller
        :items="modulesWithComponent"
        :min-item-size="20"
        class="scroller"
        ref="dynamicScrollerEl"
        :style="{ height: scrollHeight }"
    >
        <template v-slot="{ item, index, active }">
            <DynamicScrollerItem
                :item="item"
                :active="active"
                :data-index="index"
            >
                <component
                    v-if="moduleVisibility[index]"
                    :is="item.component"
                    :config="item.config"
                    :data-index="index"
                    :data-cy="`module-component-${item.config.component}`"
                    :data-previous="item.previousComponent"
                    :header-menu="headerMenu"
                    :filter="props.nestedFilter ? props.filter : null"
                />
            </DynamicScrollerItem>
        </template>
    </DynamicScroller>

    <template v-if="isEmpty">
        <slot name="empty" />
    </template>
</template>

<script setup>
    const props = defineProps({
        modules: Array,
        filter: Function,
        nestedFilter: Boolean,
        headerMenu: HTMLDivElement,
    })

    const { height } = useWindowSize()
    const dynamicScrollerEl = ref(null)

    const modulesWithComponent = computed(() => {
        let previousComponent = null
        return map(props.modules, (module, index) => {
            const item = {
                id: index,
                component: module.component,
                config: module,
                previousComponent,
            }
            previousComponent = module.component
            return item
        })
    })

    const scrollHeight = computed(() => {
        return !modulesWithComponent.value.length ? '0px' : `${height.value - (dynamicScrollerEl.value?.$el.getBoundingClientRect().top ?? 0)}px`
    })

    const moduleVisibility = computed(() => {
        return map(props.modules, (module, key) => {
            if (!props.filter) {
                return true
            }
            if (!props.nestedFilter) {
                return props.filter(module)
            }
            return some(module.items, (child) => props.filter(child))
        })
    })

    const isEmpty = computed(() => {
        return every(moduleVisibility.value, (visible) => !visible)
    })

</script>
