<template>
    <!-- TODO this has a negative margin to fix issue with header, consider alternatives -->
    <div class="px-4 flex flex-col items-center justify-center min-h-full w-full -mt-[54px] py-6 pt-[54px] shrink-0">
        <div class="flex flex-col items-center justify-center min-h-full shrink-0">
            <div v-if="pinButtons" class="mt-auto" />
            <div v-if="icon">
                <UIIcon :icon="icon" class="text-gray-500" :size="64" weight="thin" />
            </div>
            <div class="text-md font-medium mt-6 mb-2 text-center" :style="{ color: titleColor }">{{ title }}</div>
            <div class="text-sm text-gray-600 mb-8 text-center">{{ body }}</div>
            <div class="flex" />
            <!-- Apply full-width so buttons to can flex to the width of the content -->
            <div v-if="buttons?.length" class="flex flex-col items-center justify-center w-full space-y-3" :class="{ 'mt-auto': pinButtons }">
                <UIButton
                    v-for="(button, index) in buttons"
                    :key="index"
                    @click="handleButton(button)"
                    :type="button.type"
                    :class="{ 'min-w-full': button.flex }"
                    :loading="submitting"
                    :icon="button.icon"
                >
                    {{ button.title }}
                </UIButton>
            </div>
        </div>
    </div>
</template>

<script setup>
    import UIIcon from '@/components/ui/UIIcon.vue'

    const router = useIonRouter()
    const moduleHelpers = useModuleHelpers()

    const props = defineProps({
        config: Object,
    })

    const icon = ref(props.config.data.icon)
    const buttons = ref(props.config.data.buttons)
    const title = ref(props.config.data.title)
    const body = ref(props.config.data.body)
    const titleColor = ref(props.config.data.title_color)
    const pinButtons = ref(props.config.data.pin_buttons)

    const submitting = ref(false)

    const handleButton = (button) => {
        submitting.value = true

        return moduleHelpers.handleClick(button, null, false)
            .then((appModule) => {
                if (!appModule || !appModule.id) {
                    return
                }
                router.push({ name: 'view', params: { id: appModule.id } })
            })
            .finally(() => {
                submitting.value = false
            })
    }
</script>
